<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW TIE UP</h4></v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-4">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="tie_up_name"
              label="Tie Up Name"
              dense
              outlined
              :rules="rules.default_max_75_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="contact_no"
              label="Contact #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="bank_code"
              label="Bank Code"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="account_no"
              label="Account #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="address"
              label="Address"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="region_id"
              dense
              outlined
              label="Address By Region"
              :items="region_items"
              item-value="id"
              item-text="region"
              :rules="rules.combobox_rule"
              @change="selected_region"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="province_id"
              dense
              outlined
              label="Address By Province"
              :items="province_items"
              item-value="id"
              item-text="province"
              :rules="rules.combobox_rule"
              @change="selected_province"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="branch_city_id"
              dense
              outlined
              label="Address By City"
              :items="branch_city_items"
              item-value="id"
              item-text="city"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-col cols="12" v-show="alert">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn class="w-full" color="primary" @click="save_request" v-if="!saving_data"> Save
              Changes
            </v-btn>
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                 v-else></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.title">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-data-table dense
                        class="mt-3"
                        :headers="headers"
                        :items="active_items"
                        disable-pagination
                        hide-default-footer
                        :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <h2>Print |
                  <v-icon
                    class="mr-2"
                    color="success"
                    @click="print_data(1)"
                    v-if="!printt"
                  >
                    {{icons.mdiPrinter}}
                  </v-icon>
                  <v-progress-circular
                    :size=25
                    :width="5"
                    color="success"
                    indeterminate
                    v-else
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>

                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearchOutline"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{
                  active_items
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id) + 1
                  }}
                </td>
                <td>
                  {{ item.tie_up_name }}
                </td>
                <td>
                  {{ item.contact_no }}
                </td>
                <td>
                  {{ item.bank_code }}
                </td>
                <td>
                  {{ item.account_no }}
                </td>
                <td>
                  {{ item.address }}
                </td>

                <td>
                  {{ item.branch_region!=null?item.branch_region.region:'' }}
                </td>
                <td>
                  {{ item.branch_province!=null?item.branch_province.province:'' }}
                </td>
                <td>
                  {{ item.branch_city!=null?item.branch_city.city:'' }}
                </td>
                <td class="text-center">
                  <v-icon v-if="!is_adding" class="mr-2" color="info" @click="edit(item)">
                    {{ icons.mdiPencil }}
                  </v-icon>
                </td>
                <td class="text-center">
                  <v-icon v-if="!is_adding" class="mr-2" color="info" @click="add_casket(item.id)">
                    {{ icons.mdiPlusBox }}
                  </v-icon>
                </td>
                <td>
                  <v-simple-table dense class="mt-2">
                    <template v-slot:default>
                      <tbody>
                      <tr v-for="item1 in item.csp_rate" :key="item1.id">
                        <td>
                          {{ item1.casket_type }}
                        </td>
                        <td>
                          {{ formatPrice(item1.amount) }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table dense
                        class="mt-3"
                        :headers="headers"
                        :items="deactive_items"
                        disable-pagination
                        hide-default-footer
                        height="550"
                        :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <h2>Print |
                  <v-icon
                    class="mr-2"
                    color="success"
                    @click="print_data(0)"
                    v-if="!printt"
                  >
                    {{icons.mdiPrinter}}
                  </v-icon>
                  <v-progress-circular
                    :size=25
                    :width="5"
                    color="success"
                    indeterminate
                    v-else
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearchOutline"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{
                  deactive_items
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id) + 1
                  }}
                </td>
                <td>
                  {{ item.tie_up_name }}
                </td>
                <td>
                  {{ item.contact_no }}
                </td>
                <td>
                  {{ item.bank_code }}
                </td>
                <td>
                  {{ item.account_no }}
                </td>
                <td>
                  {{ item.address }}
                </td>
                <td>
                  {{ item.branch_region!=null?item.branch_region.region:'' }}
                </td>
                <td>
                  {{ item.branch_province!=null?item.branch_province.province:'' }}
                </td>
                <td>
                  {{ item.branch_city!=null?item.branch_city.city:'' }}
                </td>
                <td class="text-center">
                  <v-icon v-if="!is_adding" class="mr-2" color="info" @click="edit(item)">
                    {{ icons.mdiPencil }}
                  </v-icon>
                </td>
                <td class="text-center">
                  <v-icon v-if="!is_adding" class="mr-2" color="info" @click="add_casket(item.id)">
                    {{ icons.mdiPlusBox }}
                  </v-icon>
                </td>
                <td>
                  <v-simple-table dense class="mt-2">
                    <template v-slot:default>
                      <tbody>
                      <tr v-for="item1 in item.csp_rate" :key="item1.id">
                        <td>
                          {{ item1.casket_type }}
                        </td>
                        <td>
                          {{ formatPrice(item1.amount) }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="add_casket_dialog" persistent max-width="50%">
      <v-card>
        <v-form ref="form" class="multi-col-validation">
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h4 class="font-weight-light">ADD/CHANGE CASKETS</h4></v-toolbar-title>
          </v-toolbar>
          <v-layout row wrap class="align-center mx-2 mt-5">
            <v-flex xs12 md12>
              <v-select
                class="mx-2"
                v-model="casket_type"
                dense
                outlined
                label="Casket Type"
                :items="['Jr / Plain', 'Jr / FG']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-flex>
            <v-flex xs12 md12>
              <v-text-field
                class="mx-2"
                v-model="casket_amount"
                dense
                label="Casket Amount"
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert_request">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message_request }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn class="w-full" color="primary" @click="done_request" v-if="!saving_request">
                Save
                Changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-btn color="error" @click="add_casket_dialog = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="edit_dialog" persistent max-width="90%">
      <v-card>
        <v-form ref="form" class="multi-col-validation">
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h4 class="font-weight-light">EDIT DETAILS</h4></v-toolbar-title>
          </v-toolbar>
          <v-form ref="form" class="multi-col-validation">
            <v-layout row wrap class="align-center mx-2 mt-5">
              <v-flex xs12 md12>
                <v-text-field
                  class="mx-2"
                  v-model="tie_up_name"
                  label="Tie Up Name"
                  dense
                  outlined
                  :rules="rules.default_max_75_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  class="mx-2"
                  v-model="contact_no"
                  label="Contact #"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  class="mx-2"
                  v-model="bank_code"
                  label="Bank Code"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  class="mx-2"
                  v-model="account_no"
                  label="Account #"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  class="mx-2"
                  v-model="address"
                  label="Address"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md4>
                <v-select
                  class="mx-2"
                  v-model="region_id"
                  dense
                  outlined
                  label="Address By Region"
                  :items="region_items"
                  item-value="id"
                  item-text="region"
                  :rules="rules.combobox_rule"
                  @change="selected_region"
                ></v-select>
              </v-flex>
              <v-flex xs12 md4>
                <v-select
                  class="mx-2"
                  v-model="province_id"
                  dense
                  outlined
                  label="Address By Province"
                  :items="province_items"
                  item-value="id"
                  item-text="province"
                  :rules="rules.combobox_rule"
                  @change="selected_province"
                ></v-select>
              </v-flex>
              <v-flex xs12 md4>
                <v-select
                  class="mx-2"
                  v-model="branch_city_id"
                  dense
                  outlined
                  label="Address By City"
                  :items="branch_city_items"
                  item-value="id"
                  item-text="city"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-form>
          <v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert_request">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message_request }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn class="w-full" color="primary" @click="edit_tie" v-if="!saving_request"> Update
                Changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-btn color="error" @click="edit_dialog = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose, mdiPlusBox
    , mdiPencil
    , mdiPrinter
    , mdiAccountSearchOutline
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import {P} from 'caniuse-lite/data/agents'

  const initialState = () => {
    return {
      printt: false,

      saving_data: false,
      alert: false,
      alert_message: '',

      saving_request: false,
      edit_dialog: false,
      alert_request: false,
      alert_message_request: '',

      contact_no: '',
      search: '',

      active_items: [],
      deactive_items: [],
      address: '',
      tab: '',
      tabs: [
        {title: 'ACTIVE', icon: mdiCheck},
        {title: 'DEACTIVATED', icon: mdiClose},
      ],
      account_no: '',
      bank_code: '',
      tie_up_name: '',
      is_adding: false,

      add_casket_dialog: false,
      casket_amount: '0',
      casket_type: '',
      tie_up_id: -1,
      tie_up_details: {},

      region_id: '',
      province_id: '',
      branch_city_id: '',
      region_items: [],
      province_items: [],
      branch_city_items: [],
      headers: [
        {text: 'No.', value: 'id', sortable: false},
        {text: 'Tie Up Name', value: 'tie_up_name', sortable: false},
        {text: 'Contact #', value: 'contact_no', sortable: false},
        {text: 'Bank Code', value: 'bank_code', sortable: false},
        {text: 'Account #', value: 'account_no', sortable: false},
        {text: 'Address', value: 'address', sortable: false},
        {text: 'Region', value: 'branch_region.region', sortable: false},
        {text: 'Province', value: 'branch_province.province', sortable: false},
        {text: 'City/Municipal', value: 'branch_city.city', sortable: false},
        {text: 'Edit', value: 'particulars', sortable: false},
        {text: 'Add', value: 'particulars', sortable: false},
        {text: 'Data', value: 'particulars', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPlusBox,
          mdiPencil,
          mdiPrinter,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing', 'accounting', 'admin', 'amd', 'audit', 'book', 'creative', 'ecut', 'hr', 'it', 'prot', 'trea']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('tie_ups', ['initialize_new_tie_up', 'register_tie_up', 'edit_tie_up']),
      ...mapActions('tie_up_csp_rate', ['register_tie_up_csp_rate']),
      ...mapActions('employee', ['initialize_new_employee']),
      initialize_data() {
        this.initialize_new_tie_up()
          .then(response => {
            this.active_items = response.data[0].active
            this.deactive_items = response.data[0].deactivate
          })
          .catch(error => {
            console.log(error)
          })
        this.initialize_new_employee()
          .then(response => {
            this.region_items = response.data[0].address
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_region() {
        if (this.region_items.length > 0) {
          var index = this.region_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.region_id)
          if (index >= 0) {
            this.province_items = this.region_items[index].province
            if (!this.edit_dialog) {
              this.province_id = ''
              this.branch_city_id = ''
            } else {
              this.province_id = this.tie_up_details.province_id
            }
          }
        }
      },
      selected_province() {
        if (this.province_items.length > 0) {
          var index = this.province_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.province_id)
          if (index >= 0) {
            this.branch_city_items = this.province_items[index].city
            if (!this.edit_dialog) {
              this.branch_city_id = ''
            } else {
              this.branch_city_id = this.tie_up_details.branch_city_id
            }
          }
        }
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('tie_up_name', this.tie_up_name.toUpperCase())
          data.append('contact_no', this.contact_no)
          data.append('bank_code', this.bank_code.toUpperCase())
          data.append('account_no', this.account_no.toUpperCase())
          data.append('address', this.address.toUpperCase())
          data.append('region_id', this.region_id);
          data.append('province_id', this.province_id);
          data.append('branch_city_id', this.branch_city_id);
          this.register_tie_up(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      add_casket(value) {
        this.add_casket_dialog = true
        this.tie_up_id = value
      },
      edit(value) {
        this.edit_dialog = true
        this.tie_up_details = value
        this.contact_no = value.contact_no
        this.account_no = value.account_no
        this.bank_code = value.bank_code
        this.tie_up_name = value.tie_up_name
        this.address = value.address
        this.region_id = value.region_id
        this.selected_region()
        this.selected_province()
      },
      edit_tie() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.tie_up_details.id)
          data.append('tie_up_name', this.tie_up_name.toUpperCase())
          data.append('contact_no', this.contact_no)
          data.append('bank_code', this.bank_code.toUpperCase())
          data.append('account_no', this.account_no.toUpperCase())
          data.append('address', this.address.toUpperCase())
          data.append('region_id', this.region_id);
          data.append('province_id', this.province_id);
          data.append('branch_city_id', this.branch_city_id);
          this.edit_tie_up(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      done_request() {
        this.saving_request = true
        this.alert_request = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('tie_up_id', this.tie_up_id)
          data.append('casket_type', this.casket_type)
          data.append('amount', this.casket_amount)
          this.register_tie_up_csp_rate(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert_request = true
          this.alert_message_request = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
      async print_data(value) {
        this.printt = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing


        widths = [15, 60, 50, 60, 60, 70, 50, 50, 50]
        payments_array.push([
          {text: 'No.', alignment: 'left', style: 'main_info'},
          {text: 'Tie Up Name', alignment: 'left', style: 'main_info'},
          {text: 'Contact #', alignment: 'left', style: 'main_info'},
          {text: 'Bank Code', alignment: 'left', style: 'main_info'},
          {text: 'Account #', alignment: 'left', style: 'main_info'},
          {text: 'Address', alignment: 'left', style: 'main_info'},
          {text: 'Region', alignment: 'left', style: 'main_info'},
          {text: 'Province', alignment: 'left', style: 'main_info'},
          {text: 'City/Municipal', alignment: 'left', style: 'main_info'},
        ])
        if (value === 1) {

          this.active_items.forEach(function (item, index, payment) {
            payments_array.push([
              {
                text: index + 1,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.tie_up_name,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.contact_no,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.bank_code,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.account_no,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.address,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.branch_region != null ? item.branch_region.region : '',
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.branch_province != null ? item.branch_province.province : '',
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.branch_city != null ? item.branch_city.city : '',
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          })
        } else {
          this.deactive_items.forEach(function (item, index, payment) {
            payments_array.push([
              {
                text: index + 1,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.tie_up_name,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.contact_no,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.bank_code,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.account_no,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.address,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.branch_region != null ? item.branch_region.region : '',
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.branch_province != null ? item.branch_province.province : '',
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.branch_city != null ? item.branch_city.city : '',
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          })
        }


        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image: this.position === 'ADMIN' ? this.admin :
                    (this.position === 'TREASURY' ? this.trea :
                      (this.position === 'ACCOUNTING' ? this.accounting :
                        (this.position === 'AMD' ? this.amd :
                          (this.position === 'PROPERTY CUSTODIAN' ? this.prot :
                            (this.position === 'HUMAN RESOURCE' ? this.hr :
                              (this.position === 'BOOKKEEPING' ? this.book :
                                (this.position === 'CMD' ? this.accounting : imgData))))))),
                  width: 60,
                  height: 54,
                  style: 'logo'
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'LIST OF SERVICE',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {

              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left'
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printing_, width: 34, height: 20, style: 'logo'},
                {
                  text: ' '
                },
              ],
            };
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printt = false
      },
    },
  }
</script>
